var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.didLoadData
    ? _c("div", { staticClass: "nextcloud-widget nextcloud-stats-wrapper" }, [
        _c("div", { staticClass: "server-info sep" }, [
          _vm.activeUsers
            ? _c("div", [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.activeUsersTooltip(),
                        expression: "activeUsersTooltip()",
                      },
                    ],
                  },
                  [
                    _c("i", { staticClass: "fal fa-user" }),
                    _c("em", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatNumber(_vm.storage.num_users)
                        ),
                      },
                    }),
                    _c("strong", [_vm._v(_vm._s(_vm.tt("total-users")))]),
                    _vm._v(" "),
                    _c("small", [_vm._v(_vm._s(_vm.tt("of-which")))]),
                    _c("em", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatNumber(_vm.activeUsers.last24hours)
                        ),
                      },
                    }),
                    _c("strong", [_vm._v(_vm._s(_vm.tt("active")))]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v("(" + _vm._s(_vm.tt("last-24-hours")) + ")"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _c("hr"),
          _vm.nextcloud
            ? _c("div", [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.appUpdatesTooltip(),
                        expression: "appUpdatesTooltip()",
                      },
                    ],
                  },
                  [
                    _c("i", { staticClass: "fal fa-browser" }),
                    _c("em", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatNumber(_vm.apps.num_installed)
                        ),
                      },
                    }),
                    _c("strong", [_vm._v(_vm._s(_vm.tt("applications")))]),
                    _vm.apps.num_updates_available
                      ? _c("span", { staticClass: "nc-updates" }, [
                          _c("i", { staticClass: "fal fa-download" }),
                          _c("em", [
                            _vm._v(_vm._s(_vm.apps.num_updates_available)),
                          ]),
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tt("updates-available", {
                                    plural:
                                      _vm.apps.num_updates_available > 1
                                        ? "s"
                                        : "",
                                  })
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _c(
                          "small",
                          {
                            staticClass: "disabled",
                            attrs: { "data-nc-updates": "" },
                          },
                          [_vm._v(_vm._s(_vm.tt("no-pending-updates")))]
                        ),
                  ]
                ),
                _c("hr"),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.storagesTooltip(),
                        expression: "storagesTooltip()",
                      },
                    ],
                  },
                  [
                    _c("i", { staticClass: "fal fa-file" }),
                    _c("em", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatNumber(_vm.storage.num_files)
                        ),
                      },
                    }),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.tt("files", {
                            plural: _vm.storage.num_files > 1 ? "s" : "",
                          })
                        )
                      ),
                    ]),
                    _vm._v("  "),
                    _c("small", [_vm._v(_vm._s(_vm.tt("in")))]),
                    _c("em", [_vm._v(_vm._s(_vm.storage.num_storages))]),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.tt("storages", {
                            plural: _vm.storage.num_storages > 1 ? "s" : "",
                          })
                        )
                      ),
                    ]),
                    _vm._v("  • "),
                    _c("strong", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.convertBytes(_vm.system.freespace)
                        ),
                      },
                    }),
                    _vm._v("  "),
                    _c("small", [_vm._v(_vm._s(_vm.tt("free")))]),
                  ]
                ),
                _c("hr"),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.sharesTooltip(),
                        expression: "sharesTooltip()",
                      },
                    ],
                  },
                  [
                    _c("i", { staticClass: "fal fa-share" }),
                    _c("em", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatNumber(_vm.shares.num_shares)
                        ),
                      },
                    }),
                    _c("strong", [_vm._v(_vm._s(_vm.tt("local")))]),
                    _vm._v(" "),
                    _c("small", [_vm._v(" " + _vm._s(_vm.tt("and")))]),
                    _c("em", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatNumber(
                            _vm.shares.num_fed_shares_sent +
                              _vm.shares.num_fed_shares_received
                          )
                        ),
                      },
                    }),
                    _c("strong", [
                      _vm._v(" " + _vm._s(_vm.tt("federated-shares")) + " "),
                    ]),
                  ]
                ),
                _c("hr"),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }